<template>
  <div class="tooltip-container" @mouseover="showTooltip" @mouseleave="hideTooltip">
    <slot></slot>
    <div v-if="show" class="tooltip-content" ref="tooltip">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  data() {
    return {
      show: false,
    };
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    showTooltip() {
      this.show = true;
      this.$nextTick(() => {
        const tooltipEl = this.$refs.tooltip;
        const rect = tooltipEl.getBoundingClientRect();

        if (rect.right > window.innerWidth) {
          tooltipEl.style.left = "auto";
          tooltipEl.style.right = "0";
          tooltipEl.style.transform = "translateX(0)";
        } else if (rect.left < 0) {
          tooltipEl.style.left = "0";
          tooltipEl.style.transform = "translateX(0)";
        }
      });
    },
    hideTooltip() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1000;
}
</style>

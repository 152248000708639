<template>
  <div class="accordion-item">
    <div class="accordion-header" @click="toggle">
      <slot name="header"></slot>
      <BaseIcon :icon="isOpen ? 'minus' : 'plus'" />
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave" enter-active-class="accordion-content-enter-active" leave-active-class="accordion-content-leave-active">
      <div v-show="isOpen" class="accordion-content" ref="content">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    defaultOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.defaultOpen,
    };
  },
  mounted() {
    this.observer = new MutationObserver(() => {
      if (this.isOpen) {
        this.updateHeight();
      }
    });

    this.observer.observe(this.$refs.content, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    if (this.isOpen) {
      this.$nextTick(() => this.updateHeight());
    }
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    toggle() {
      if (!this.isOpen) {
        this.$emit("opened", this);
      }
      this.isOpen = !this.isOpen;
    },
    updateHeight() {
      const contentEl = this.$refs.content;
      if (contentEl) {
        contentEl.style.height = "auto";
        //const height = contentEl.scrollHeight + "px";
        contentEl.style.height = "auto";
      }
    },
    close() {
      this.isOpen = false;
      this.$refs.content.style.height = "0";
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el, done) {
      el.style.height = el.scrollHeight + "px";
      el.addEventListener("transitionend", done);
    },
    afterEnter(el) {
      el.style.height = "auto";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el, done) {
      setTimeout(() => (el.style.height = "0"), 0);
      el.addEventListener("transitionend", done);
    },
  },
};
</script>

<style>
.accordion-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content {
  transition: height 0.5s ease-in-out;
}

.accordion-content-enter-active,
.accordion-content-leave-active {
  overflow: hidden;
}

.icon {
  cursor: pointer;
}
</style>

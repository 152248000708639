<template>
  <div class="accordion">
    <slot></slot>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$children.forEach(() => {
      //child
      // child.$on("opened", (openedItem) => {
      //   this.closeOthers(openedItem);
      // });
    });
  },
  methods: {
    closeOthers(openedItem) {
      this.$children.forEach((child) => {
        if (child !== openedItem) {
          child.close();
        }
      });
    },
  },
};
</script>

<template>
  <modal name="modal-trip-history" class="trip-history" transition="pop-out" width="100%" :focus-trap="true" :min-height="500" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="reset">
    <div class="flex justify-between modal-header less-margin-bottom text-lg bg-primary-50 font-serif">
      <LoadingSpinner :isLoading="isLoading" v-if="isLoading" />
      <div class="pl-3 py-2">
        <date-picker v-model="reportFor" :lang="date_picker_lang" :key="date_picker_langKey" type="date" :editable="false" :clearable="false" @change="getRouteResult()"></date-picker>
      </div>
      <div class="">
        <div class="mt-2 text-black">{{ alarm.unit_name }}</div>
      </div>
      <div class="pr-2">
        <span class="close-button cursor-pointer" @click="hide()"><BaseIcon icon="times-circle" class="text-red-700"/></span>
      </div>
    </div>
    <div class="modal-body less-side-padding border border-secondary-300 responsive-height" style="top: 0 !important;">
      <div class="flex flex-wrap ">
        <div class="relative w-full lg:w-2/12 flex flex-col border border-secondary-300">
          <div class="absolute w-full top-0 left-0 z-10 bg-white h-10 px-2">
            <div class="mt-2 border border-secondary-300 flex justify-between px-2 py-2">
              <div class="flex">
                <tooltip message="See optimized route">
                  <div class="p-2 mr-2 w-10 text-center" :class="optimizedPathIsReady ? 'btn-red' : 'btn-red-outline'" @click="getOptimizedRoute">
                    <BaseIcon icon="route" class="cursor-pointer text-lg" />
                  </div>
                </tooltip>
                <tooltip message="Change route color" :disabled="routeResults.length === 0">
                  <div class="p-2 w-10 text-center" :class="pathColorChanged ? 'btn-blue' : 'btn-blue-outline'" @click="ChangePathColor()">
                    <BaseIcon icon="palette" class="cursor-pointer text-lg" />
                  </div>
                </tooltip>
              </div>
              <div class="">
                <div class="mt-2">{{ formatDist(totalDist) }} km, {{ moment.duration(totalTripDuration, "seconds").humanize() }}</div>
              </div>
            </div>
          </div>

          <div class="pt-2 mt-16 px-2 pb-10 overflow-auto border border-secondary-300" ref="rightSidebar" style="height: 83vh;">
            <div v-for="(v, i) in routePlayedItems" :key="i" class="cursor-pointer mb-2">
              <div @click="focusRouteItem(v)" v-if="v.type == 'waiting'" class="bg-red-200 p-2" :class="i == routePlayedItems.length - 1 ? 'border-4 border-primary-500' : ''">
                <div class="font-bold">
                  <div class="text-right text-gray-600" :class="v.duration > 1800 ? 'text-red-700' : ''">Stop {{ v.order }}</div>
                </div>
                <div class="font-bold text-left text-sm" :class="v.duration > 1800 ? ' text-red-700' : ''">{{ v.address | formatAddress }}</div>
                <div class="font-semibold">
                  <div class="text-right text-sm" :class="v.duration > 1800 ? 'text-red-700' : ''">{{ moment.duration(v.duration, "seconds").humanize() }}</div>
                </div>
                <div class="">
                  <div class="text-right text-sm" :class="v.duration > 1800 ? 'font-bold text-red-700' : ''">{{ moment(v.startTime).format("HH:mm:ss") }} - {{ moment(v.endTime).format("HH:mm:ss") }}</div>
                </div>
              </div>
              <div @click="focusRouteItem(v)" v-if="v.type == 'trip'" :class="i == routePlayedItems.length - 1 ? 'border-4 border-primary-500' : 'border border-gray-100'">
                <div class="font-bold pt-1 pb-2 px-2 flex justify-between" :style="{ 'background-color': `${v.color}70` }" :class="i == routePlayedItems.length - 1 ? 'bg-primary-500' : ''">
                  <div class=""><BaseIcon icon="caret-up" class="text-gray-800 font-bold text-sm" /></div>
                  <div class="text-gray-900">Trip {{ v.order }}</div>
                </div>
                <div class="text-xxs text-gray-600 mt-1 px-2">{{ v.startAddress | formatAddress }}</div>
                <div class="text-xxs text-gray-600 px-2"><BaseIcon icon="arrow-down" /></div>
                <div class="text-xxs text-gray-600 px-2">{{ v.endAddress | formatAddress }}</div>
                <div class="mt-1 px-2">
                  <span v-if="isPlaying && i == routePlayedItems.length - 1">{{ (v.currentDist / 1000).toFixed(2) }} / </span><span class="font-semibold ">{{ (v.distance / 1000).toFixed(2) }} km</span>
                </div>
                <div class="font-semibold px-2">
                  <div class="text-right">{{ moment.duration(v.duration, "seconds").humanize() }}</div>
                </div>
                <div class="mt-1 pb-2 px-2 flex justify-between">
                  <div class=""><BaseIcon icon="play-circle" /></div>
                  <div class="">{{ moment(v.startTime).format("HH:mm:ss") }} - {{ moment(v.endTime).format("HH:mm:ss") }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="absolute bottom-1 left-0 z-10 bg-white h-12 w-full px-2">
            <div class="flex justify-between border border-secondary-300 p-2">
              <div class="">
                <span @click="playAnimation()" class="cursor-pointe text-lg">
                  <BaseIcon v-if="isPlaying && isPaused" icon="play-circle" class="mr-1 text-blue-500" />
                  <BaseIcon v-else-if="!isPlaying" icon="play-circle" class="mr-1 text-blue-500" />
                  <BaseIcon v-else icon="pause-circle" class="mr-1 text-blue-500 " />
                </span>
                <span class="cursor-not-allowed text-lg text-gray-400">
                  <BaseIcon icon="step-forward" class="mr-1" />
                </span>
                <span @click="stopAnimation()" class="cursor-pointer text-lg">
                  <BaseIcon icon="stop-circle" class="mr-1 text-gray-400" :class="{ 'text-red-500': stopPlay }" />
                </span>
              </div>
              <div class=" text-xs">
                <input class="w-10/12 cursor-pointer mt-2" :style="{ direction: '' }" type="range" min="0" max="500" step="10" v-model.number="playSpeed" />
              </div>
            </div>
          </div>
        </div>
        <div class="lg:w-10/12 flex-col relative less-side-padding border border-secondary-300">
          <transition name="fade">
            <div class="optimized-path" v-show="optimizedPathIsReady">
              <route_timeline />
            </div>
          </transition>
          <div class="sticky-info-panel" v-if="isPlaying">
            <div v-html="stickyInfoContent"></div>
          </div>
          <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" class="w-full h-full" ref="tripHistoryMap">
            <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"></GmapInfoWindow>
            <GmapCircle v-for="(pin, index) in trackedPoints" :key="index + 'c'" :center="pin.position" :visible="true" :options="pin.options" @mouseover="showInfoWindow($event, pin.infoText, true)" @mouseout="showInfoWindow($event, pin.infoText, false)"></GmapCircle>
            <GmapMarker :position="marker.position" :clickable="false" :draggable="false" :icon="marker.icon"></GmapMarker>
            <GmapMarker v-for="(m, index) in trackedPoints" :label="{ text: `${index + 1}`, color: 'white', fontSize: '12px', fontWeight: 'bold' }" :position="m.position" :clickable="false" :draggable="false" :key="index" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)"> </GmapMarker>
            <GmapPolyline v-for="(p, i) in trackedPaths" :key="i + 'p'" :path="p.path" :options="p.options" @mouseover="showInfoWindow($event, p.infoText, true, i)" @mouseout="showInfoWindow($event, p.infoText, false, i)"></GmapPolyline>
            <DirectionsRenderer :key="mapKey" travelMode="DRIVING" :origin="startPosition" :optimizeWaypoints="true" :destination="endPosition" :waypoints="waypoints" :options="{ draggable: false, zIndex: 99999, suppressMarkers: true, strokeColor: optimizedPathColor.activeColor, strokeWeight: 2 }" @directions_changed="directionChanged" @error_direction="handleDirectionError" />

            <template v-if="routeResults.length > 0 && trackedPoints.length == 0 && isPlaying === false">
              <template v-for="(m, index) in routeResults.filter((x) => x.type === 'waiting')">
                <GmapMarker :label="{ text: `${index + 1}`, color: 'white', fontSize: '12px', fontWeight: 'bold' }" :options="{ draggable: false, clickable: true, visible: true }" :position="m.position" :clickable="false" :draggable="false" :key="`S-${index}`" @mouseover="showInfoWindow3($event, m.infoText, true)" @mouseout="showInfoWindow3($event, m.infoText, false)"> </GmapMarker>
              </template>
              <GmapPolyline v-for="(rp, ri) in routeResults" :key="ri" :path="rp.path" :options="rp.options" @mouseover="showInfoWindow3($event, rp.infoText, true, ri)" @mouseout="showInfoWindow3($event, rp.infoText, false, ri)"></GmapPolyline>
            </template>
          </GmapMap>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 1500;

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/index.css";
import { gmapApi } from "vue2-google-maps";
import tooltip from "@/components/base/tooltip";
import DirectionsRenderer from "@/components/map/DirectionsRenderer";
import route_timeline from "@/components/route_timeline";
//import accordion from "@/components/base/accordion";
//import AccordionItem from "@/components/base/accordionItem";

export default {
  name: "TripTracker",

  components: {
    DatePicker,
    tooltip,
    DirectionsRenderer,
    route_timeline,
    //accordion,
    //AccordionItem,
  },

  props: {},

  data() {
    return {
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      selectedAlarm: null,
      datePickerOpen: false,
      updatedWayouts: [],
      reportFor: new Date(this.moment().startOf("day")),
      isLoading: false,
      // autocomplete
      startMarkerVisible: true,
      endMarkerVisible: true,
      destinations: [{ address: "" }],
      // backend
      startPosition: null,
      endPosition: null,
      departureTime: null,
      maxWaitingTime: 30,
      maxStops: 3,
      // drivingOptions: null,
      definedRoute: [],
      waypoints: [],
      savedWaypoints: [],
      totalDistance: null,
      totalDuration: null,
      startAddress: "",
      endAddress: "",
      expectedArrival: "",
      emailAddress: "",
      smsNumber: "",
      renderDirection: true,
      trackedRoute: [],
      waitingCircles: [],
      // info window
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: { content: "", icon: "", pixelOffset: { width: 0, height: -35 } },
      selectedUnits: null,
      isAlarmSelectDisabled: false,
      isRouteTabOpen: false,
      drag: false,
      listRenderKey: 0,
      isPlaying: false,
      isPaused: false,
      stopPlay: false,
      playSpeed: 400,
      totalDist: 0,
      totalTripDuration: 0,
      routeResults: [],
      trackedPoints: [],
      trackedHistoryPoints: [],
      historyTripsPoints: [],

      // rawPath: {
      //   path: [],
      //   geodesic: true,
      //   options: {
      //     strokeColor: "red",
      //     strokeOpacity: 0.8,
      //     strokeWeight: 2,
      //     icons: [],
      //   },
      //   infoText: "",
      // },
      marker: {},
      trackedPaths: [],
      routePlayedItems: [],
      imei: "",
      mapIsReady: false,
      pathColor: { regularColor: "red", changedColor: "#1A8DE9", activeColor: "red" },
      optimizedPathColor: { regularColor: "#008000", changedColor: "red", activeColor: "#008000" },
      alarm: { unit_name: "" },
      imei_number: "",
      optimizedPathIsReady: false,
      pathColorChanged: false,
      mapKey: Date.now(),
      mapStartPosition: null, //for map bund
      mapEndPosition: null, //for map bund
      stickyInfoContent: null,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
    };
  },
  computed: {
    google: gmapApi,
    tripStartTime() {
      if (this.updateInfo && this.updateInfo.trip_log.length > 0) {
        let l = _.find(this.updateInfo.trip_log, { type: "trip_start" });
        if (l) return this.formatDate(l.time);
      }
      return "";
    },
    actualDelay() {
      const maxSpeed = 500;
      const exponent = 3;
      const minDelay = 10;
      const maxDelay = 500;
      const speedRatio = this.playSpeed / maxSpeed;
      const delay = maxDelay * Math.pow(1 - speedRatio, exponent);
      return Math.max(minDelay, delay);
    },
  },
  filters: {
    formatAddress: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(0, value.lastIndexOf(","));
    },
  },
  watch: {
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },

  methods: {
    async getRouteResult() {
      //console.log("getRouteResult::imei_number==>", this.imei_number);
      try {
        this.zoom = this.$store.state.mapZoom;
        this.center = this.$store.state.mapCenter;
        this.pathColorChanged = false;

        this.trackedPoints = []; // waiting stops
        this.trackedPaths = []; // trips
        this.routePlayedItems = [];
        // play route animation reset
        this.isPlaying = false;
        this.stopPlay = false;
        this.infoWinOpen = false;
        this.infoOptions.content = null;
        // reset optimize route
        this.optimizedPathIsReady = false;
        this.startPosition = null;
        this.endPosition = null;
        this.waypoints = [];
        this.mapKey = Date.now();
        // end optimize route
        this.isLoading = true;
        this.routeResults = [];
        this.routePlayedItems = [];
        this.trackedPoints = [];
        this.trackedPaths = [];
        this.trackedHistoryPoints = [];
        this.historyTripsPoints = [];
        this.waypoints = [];
        this.startPosition = null;
        this.endPosition = null;

        let start = new Date(this.moment(this.reportFor).startOf("day")).toISOString();
        let end = null;
        // let end = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/route-analytics/${this.imei_number}/${start}/${end}`);

        this.routeResults = response.data.routeResults;

        if (this.routeResults.length > 0) {
          this.routePlayedItems = this.routeResults;
          this.alarm = response.data.alarm;

          const firstElement = this.routeResults[0];
          if (firstElement.type === "trip") {
            //this.startPosition = firstElement.startPosition;
            this.mapStartPosition = firstElement.startPosition;
          }

          if (firstElement.type === "waiting") {
            //this.startPosition = firstElement.position;
            this.mapStartPosition = firstElement.position;
          }

          const lastElement = this.routeResults[this.routeResults.length - 1];
          if (lastElement.type === "trip") {
            //this.endPosition = lastElement.startPosition;
            this.mapEndPosition = lastElement.startPosition;
          }

          if (lastElement.type === "waiting") {
            //this.endPosition = lastElement.position;
            this.mapEndPosition = lastElement.position;
          }

          // let vm = this;

          // for (let i = 0; i < this.routeResults.length; i++) {
          //   let item = this.routeResults[i];
          //   let infoText = "";
          //   //console.log("item.startAddress =", r);
          // }

          this.setMapBounds();
          await this.reverseGeocodeAddresses();
        }

        this.mapIsReady = true;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.mapIsReady = true;
        this.handleError(error);
      }
    },
    getOptimizedRoute() {
      this.optimizedPathIsReady = !this.optimizedPathIsReady;

      if (!this.optimizedPathIsReady) {
        this.startPosition = null;
        this.endPosition = null;
        this.waypoints = [];
        this.mapKey = Date.now();
      }

      if (this.optimizedPathIsReady && this.waypoints.length === 0) {
        const firstElement = this.routeResults[0];
        if (firstElement.type === "trip") {
          this.startPosition = firstElement.startPosition;
          this.mapStartPosition = firstElement.startPosition;
        }

        if (firstElement.type === "waiting") {
          this.startPosition = firstElement.position;
          this.mapStartPosition = firstElement.position;
        }

        const lastElement = this.routeResults[this.routeResults.length - 1];

        if (lastElement.type === "trip") {
          this.endPosition = lastElement.startPosition;
          this.mapEndPosition = lastElement.startPosition;
        }

        if (lastElement.type === "waiting") {
          this.endPosition = lastElement.position;
          this.mapEndPosition = lastElement.position;
        }

        let wayPoints = this.routeResults.slice(1, -1);
        wayPoints.forEach((r) => {
          if (r.type === "waiting") {
            this.waypoints.push({ location: r.position });
          }
        });
      }
    },
    directionChanged(data) {
      //console.log("direction changed", data);

      //this.startMarkerVisible = false;
      //this.endMarkerVisible = false;

      // todo: incase of multiple stop, calculate start, end, duration, distance. Calculate for each leg
      // let totalDuration = data.routes[0].legs[0].duration.value; // seconds
      // let totalDistance = data.routes[0].legs[0].distance.value; // meters
      // let startPosition = { lat: data.routes[0].legs[0].start_location.lat(), lng: data.routes[0].legs[0].start_location.lng() };
      // let startAddress = data.routes[0].legs[0].start_address;
      // let endPosition = { lat: data.routes[0].legs[0].end_location.lat(), lng: data.routes[0].legs[0].end_location.lng() };
      // let endAddress = data.routes[0].legs[0].end_address;
      //let definedRoute = data.routes[0].overview_path;

      let waypoints = [];
      for (let i = 0; i < data.routes[0].legs[0].via_waypoints.length; i++) {
        let w = data.routes[0].legs[0].via_waypoints[i];
        waypoints.push({ lat: w.lat(), lng: w.lng() });
      }

      this.savedWaypoints = waypoints;
      this.calculateExpectedArrival();
      this.$emit("routeChanged", this.definedRoute);
    },
    setMapBounds() {
      var vm = this;

      //setTimeout(() => {
      this.$nextTick(() => {
        vm.$refs.tripHistoryMap.$mapPromise.then((map) => {
          const bounds = new vm.google.maps.LatLngBounds();

          if (vm.mapStartPosition) bounds.extend(vm.mapStartPosition);
          if (vm.mapEndPosition) bounds.extend(vm.mapEndPosition);

          map.fitBounds(bounds);
          vm.zoom = 12;
        });
      });

      //}, 500);
    },

    async reverseGeocodeAddresses() {
      let vm = this;
      this.routeResults.forEach(async (r) => {
        // todo: use same address for lastWaitingStop & tripStartAddress, tripEndAdress & waitingStop
        r.options = {
          strokeColor: "red",
          strokeOpacity: 1,
          strokeWeight: 3,
        };
        if (r.type == "trip") {
          let startAddress = await this.reverseGeocode(r.startPosition);
          let endAddress = await this.reverseGeocode(r.endPosition);

          r.startAddress = startAddress;
          r.endAddress = endAddress;

          r.infoText = `<b>Trip ${r.order}</b><br>
                    ${vm.formatAdd(r.startAddress)} -> ${vm.formatAdd(r.endAddress)}<br>
                    ${vm.moment(r.startTime).format("HH:mm:ss")} -> ${vm.moment(r.endTime).format("HH:mm:ss")}, ${vm.moment.duration(r.duration, "seconds").humanize()}<br>
                    ${vm.formatDist(r.distance)} km<br>`;
        } else if (r.type == "waiting") {
          let address = await this.reverseGeocode(r.position);
          r.address = address;
          r.infoText = `<b>Stop ${r.order}</b><br>
                    <b>${vm.formatAdd(r.address)}</b><br>
                    ${vm.moment(r.startTime).format("HH:mm")} - ${vm.moment(r.endTime).format("HH:mm")}, ${vm.moment.duration(r.duration, "seconds").humanize()}`;
        }
      });
    },

    async reverseGeocode(point) {
      var vm = this;
      let address = "";

      if (!point) return address;

      try {
        const geocoder = new vm.google.maps.Geocoder();
        let res = await geocoder.geocode({ location: point });
        address = res.results[0].formatted_address;
      } catch (error) {
        console.log(error);
      }

      return address;
    },

    processRoutePlayedItems() {
      this.routePlayedItems = [];

      for (let i = 0; i < this.routeResults.length; i++) {
        let r = this.routeResults[i];
        this.routePlayedItems.push(r);
      }
    },
    async playRouteSequence() {
      this.trackedPoints = []; // Reset waiting stops
      this.trackedPaths = []; // Reset trips
      this.routePlayedItems = [];
      this.totalDist = 0; // Reset total distance for selected period
      this.totalTripDuration = 0;
      this.$refs.tripHistoryMap.$mapObject.setZoom(13);

      this.marker = {
        position: null,
        icon: { path: this.google.maps.SymbolPath.CIRCLE, scale: 4 },
      };

      for (let i = 0; i < this.routeResults.length; i++) {
        let r = this.routeResults[i];

        this.routePlayedItems.push(r);
        this.scrollToBottom();

        let infoText = "";
        r.currentDist = 0;

        if (this.stopPlay) {
          this.isPlaying = false;
          this.isPaused = false;
          this.stopPlay = false;
          this.processRoutePlayedItems();
          return;
        }

        if (r.type === "trip") {
          this.trackedPaths.push({
            path: [],
            geodesic: true,
            options: {
              strokeColor: "#1A8DE9",
              strokeOpacity: 1,
              strokeWeight: 4,
              zIndex: 999,
              icons: [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2 }, offset: "100%", repeat: "700px" }],
            },
            infoText: "",
          });

          for (let j = 0; j < r.path.length; j++) {
            while (this.isPaused) {
              await new Promise((resolve) => setTimeout(resolve, 100)); // Pause functionality
            }

            let point = r.path[j];
            // Constructing infoText for each point
            infoText = `<b>Trip ${r.order}</b><br>
              ${this.formatAdd(r.startAddress)} -> ${this.formatAdd(r.endAddress)}<br>
              ${this.moment(r.startTime).format("HH:mm")} -> ${this.moment(r.endTime).format("HH:mm")}, ${this.moment.duration(r.duration, "seconds").humanize()}, ${this.formatDist(r.distance)} km<br>
              <b>${this.moment(point.time).format("HH:mm:ss")}, ${this.formatDist(point.dist)} km, ${this.formatSpeed(point.speed)} km/hr</b>`;

            this.trackedPaths[this.trackedPaths.length - 1].path.push(point);

            if (!this.stopPlay) {
              await new Promise((resolve) => setTimeout(resolve, this.actualDelay));
            }

            // Update marker position and info text as before...
            this.marker.position = point;
            this.stickyInfoContent = infoText; // Update stickyInfoContent with the latest infoText
            // Assuming additional logic here as needed
            r.currentDist = point.dist;
          }

          // Finalizing each trip as before...
          let color = this.getRandomPathColor();
          let lastPathIndex = this.trackedPaths.length - 1;
          this.trackedPaths[lastPathIndex].options.strokeColor = color;
          this.trackedPaths[lastPathIndex].infoText = infoText; // Assigning the last constructed infoText to the path
          r.infoText = infoText;
          r.position = r.startPosition;
          r.color = color;

          this.totalDist += r.distance;
          this.totalTripDuration += r.duration;
        } else if (r.type == "waiting") {
          // Handle waiting type without changes
          infoText = `<b>Stop ${r.order}</b><br>
            <b>${this.formatAdd(r.address)}</b><br>
            ${this.moment(r.startTime).format("HH:mm")} - ${this.moment(r.endTime).format("HH:mm")}, ${this.moment.duration(r.duration, "seconds").humanize()}`;

          this.trackedPoints.push({
            ...r,
            options: {
              fillColor: "red",
              fillOpacity: 0,
              strokeColor: "red",
              strokeOpacity: 1,
              strokeWeight: 4,
              radius: 80,
            },
            infoText: infoText,
          });

          if (!this.stopPlay) {
            await new Promise((resolve) => setTimeout(resolve, this.actualDelay));
          }
        }
      }

      this.isPlaying = false; // Ensure isPlaying is false when the sequence completes
    },

    scrollToBottom() {
      this.$nextTick(function() {
        let el = this.$refs.rightSidebar;
        el.scrollTop = el.scrollHeight;
      });
    },

    getRandomPathColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    ChangePathColor() {
      this.pathColorChanged = !this.pathColorChanged;
      // this.pathColor.activeColor = this.pathColor.activeColor === this.pathColor.regularColor ? this.pathColor.changedColor : this.pathColor.regularColor;
      // this.optimizedPathColor.activeColor = this.optimizedPathColor.activeColor === this.optimizedPathColor.regularColor ? this.optimizedPathColor.changedColor : this.optimizedPathColor.regularColor;

      if (this.pathColorChanged) {
        const colors = this.routeResults.map(() => this.getRandomPathColor());
        this.routeResults.forEach((r, index) => {
          const color = colors[index];
          r.options = {
            strokeColor: color,
            strokeOpacity: 1,
            strokeWeight: 3,
          };
        });
      } else {
        this.routeResults.forEach((r) => {
          r.options = {
            strokeColor: "red",
            strokeOpacity: 1,
            strokeWeight: 3,
          };
        });
      }
    },

    async changeLocationEnd(event) {
      try {
        let position = { lat: event.latLng.lat(), lng: event.latLng.lng() };

        const geocoder = new this.google.maps.Geocoder();
        let response = await geocoder.geocode({ location: position });

        this.$refs.endPlace.$refs.input.value = response.results[0].formatted_address;
      } catch (error) {
        console.log(error);
      }
    },

    // directionChanged(data) {
    //   this.focusMarker({ lat: 23.8758547, lng: 90.3795438 });
    //   // console.log(data,'direction changed');

    //   this.startMarkerVisible = false;
    //   this.endMarkerVisible = false;

    //   // todo: incase of multiple stop, calculate start, end, duration, distance. Calculate for each leg
    //   this.totalDuration = data.routes[0].legs[0].duration.value; // seconds
    //   // if(data.routes[0].legs[0].duration_in_traffic) this.totalDuration = data.routes[0].legs[0].duration_in_traffic.value;

    //   this.totalDistance = data.routes[0].legs[0].distance.value; // meters

    //   this.startPosition = { lat: data.routes[0].legs[0].start_location.lat(), lng: data.routes[0].legs[0].start_location.lng() };
    //   this.startAddress = data.routes[0].legs[0].start_address;

    //   this.endPosition = { lat: data.routes[0].legs[0].end_location.lat(), lng: data.routes[0].legs[0].end_location.lng() };
    //   this.endAddress = data.routes[0].legs[0].end_address;

    //   //TODO: check how the data is set
    //   //overview_path
    //   this.definedRoute = data.routes[0].overview_path;

    //   let waypoints = [];
    //   for (let i = 0; i < data.routes[0].legs[0].via_waypoints.length; i++) {
    //     let w = data.routes[0].legs[0].via_waypoints[i];
    //     waypoints.push({ lat: w.lat(), lng: w.lng() });
    //   }
    //   this.savedWaypoints = waypoints;
    //   this.calculateExpectedArrival();
    //   // this.$emit('routeChanged', this.definedRoute);
    // },

    calculateExpectedArrival() {
      if (this.totalDuration) {
        let totalWaitingTime = parseInt(this.maxWaitingTime) * parseInt(this.maxStops);
        this.expectedArrival = this.moment(this.departureTime)
          .add(totalWaitingTime, "minutes")
          .add(this.totalDuration, "seconds")
          .format("YYYY-MM-DD, HH:mm");
      }
    },

    handleDirectionError(status) {
      if (status == "ZERO_RESULTS") {
        this.handleError(null, "Ingen direkt rutt", "top-right", 3000);
        this.startMarkerVisible = true;
        this.endMarkerVisible = true;
      }

      if (status == "INVALID_REQUEST") {
        this.handleError(null, "Avgångstid måste vara i framtiden", "top-right", 1500);
        this.checkDepartureTime();
      }
    },

    copyDirectionLink() {
      var vm = this;
      let origin = `${this.startPosition.lat},${this.startPosition.lng}`;
      let destination = `${this.endPosition.lat},${this.endPosition.lng}`;
      let waypoints = "";
      for (let i = 0; i < this.savedWaypoints.length; i++) {
        let w = this.savedWaypoints[i];
        waypoints += `${w.lat},${w.lng}|`;
      }

      let url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving&waypoints=${waypoints}`;

      const cb = navigator.clipboard;
      cb.writeText(url).then(() => vm.handleSuccess("Vägbeskrivning kopierad"));
    },
    async getFormattedAddress(position) {
      try {
        //let position = { lat: location.lat(), lng: event.latLng.lng() };

        const geocoder = new this.google.maps.Geocoder();
        let response = await geocoder.geocode({ location: position });

        return response.results[0].formatted_address;
      } catch (error) {
        console.log(error);
      }
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    formatAdd: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(0, value.indexOf(","));
    },

    formatDist(value) {
      return (value / 1000).toFixed(2);
    },

    formatSpeed(value) {
      return Math.ceil(value);
    },

    disableTime(date) {
      let today = this.moment();
      today.subtract(1, "hour");
      return date < new Date(today);
    },

    handleTimeSelection(value, type) {
      // eslint-disable-line
      if (type === "minute") {
        this.datePickerOpen = false;
      }

      this.checkDepartureTime();
      this.calculateExpectedArrival();
    },

    checkDepartureTime() {
      let departureTimeMoment = this.moment(this.departureTime);
      let now = this.moment();
      if (departureTimeMoment.isBefore(now)) {
        let t = 15 - (now.minutes() % 15);
        now.add(t, "minutes");
        this.departureTime = new Date(now);
      }
    },

    initDate() {
      this.departureTime = new Date(
        this.moment()
          .add(2, "hours")
          .startOf("hour")
      );
    },

    stopAnimation() {
      this.stopPlay = !this.stopPlay;

      if (this.stopPlay) {
        this.isPlaying = false;
        this.isPaused = false;
        this.playRouteSequence();
        this.stopPlay = false;
      }
    },
    playAnimation() {
      this.stopPlay = false;
      // If not currently playing, start the animation
      if (!this.isPlaying && !this.isPaused) {
        this.isPlaying = true;
        this.isPaused = false;
        this.playRouteSequence();
      } else if (this.isPlaying && !this.isPaused) {
        // If currently playing and not paused, pause the animation
        this.isPaused = true;
      } else if (this.isPlaying && this.isPaused) {
        // If currently paused, resume the animation
        this.isPaused = false;
      }
    },

    setupTripInfo() {
      //var vm = this;
    },
    reset() {
      this.startLocation = null;
      this.endLocation = null;
      this.routeResults = [];
      this.routePlayedItems = [];
      this.trackedPoints = [];
      this.trackedPaths = [];
      this.trackedHistoryPoints = [];
      this.historyTripsPoints = [];
      this.alarm.unit_name = "";
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    showTripReport(log) {
      let logType = "";
      if (log.type == "trip_start") logType = "Resan börjar";
      else if (log.type == "trip_end") logType = "Resan genomförd";
      else if (log.type == "waiting_start") logType = "Väntan började";
      else if (log.type == "waiting_end") logType = "Väntande slut";
      else if (log.type == "on_track") logType = "På rutt";
      else if (log.type == "deviated") logType = "Avvikit från rutt";

      let infoText = `
        ${logType}<br>
        ${this.formatDate(log.time)}
      `;
      if (log.type == "waiting_end") infoText += `<br>${log.duration} min.`;

      this.showInfoWindow(log.position, infoText, true);
      this.zoomPosition(log.position);
    },

    showInfoWindow2(position, infoText, show) {
      this.infoWindowPos = position;
      this.infoOptions.content = infoText;
      this.infoWinOpen = show;
    },

    showInfoWindow(event, infoText, show, index = -1) {
      if (show) {
        // set
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
        if (index > -1) {
          this.trackedPaths[index].options.strokeWeight = 4;
          this.trackedPaths[index].options.strokeOpacity = 1;

          for (let i = 0; i < this.trackedPaths.length; i++) {
            let p = this.trackedPaths[i];
            if (i != index) {
              p.options.strokeOpacity = 0.2;
              p.options.strokeWeight = 2.5;
            }
          }
        }
      } else {
        // reset
        this.infoWinOpen = show;
        for (let i = 0; i < this.trackedPaths.length; i++) {
          let p = this.trackedPaths[i];
          p.options.strokeOpacity = 1;
          p.options.strokeWeight = 2.5;
        }
      }
    },

    showInfoWindow3(event, infoText, show, index = -1) {
      if (show) {
        // set
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
        if (index > -1) {
          const selectedRoute = this.routeResults[index];
          const updatedOptions = {
            ...selectedRoute.options,
            strokeWeight: 4,
            strokeOpacity: 1,
          };
          this.routeResults[index].options = updatedOptions;
          for (let i = 0; i < this.routeResults.length; i++) {
            if (i !== index) {
              const otherRoute = this.routeResults[i];
              const updatedOptions = {
                ...otherRoute.options,
                strokeWeight: 3,
                strokeOpacity: 0.2,
              };
              this.routeResults[i].options = updatedOptions;
            }
          }
        }
      } else {
        // reset
        this.infoWinOpen = show;
        this.routeResults.forEach((p) => {
          p.options = {
            ...p.options,
            strokeWeight: 3,
            strokeOpacity: 1,
          };
        });
      }
    },

    focusRouteItem(point) {
      var vm = this;
      if (point && point.position) {
        setTimeout(() => {
          vm.$refs.tripHistoryMap.$mapObject.panTo(point.position);
          vm.$refs.tripHistoryMap.$mapObject.setZoom(13);

          if (point.type == "trip") {
            vm.showInfoWindow2({ latLng: point.position }, point.infoText, true, point.order - 1);
          } else {
            vm.showInfoWindow2({ latLng: point.position }, point.infoText, true);
            for (let i = 0; i < this.trackedPaths.length; i++) {
              let p = this.trackedPaths[i];
              p.options.strokeOpacity = 1;
              p.options.strokeWeight = 2.5;
            }
          }
        }, 300);
      }
    },

    zoomPosition(position) {
      var vm = this;
      setTimeout(() => {
        vm.$refs.tripHistoryMap.$mapObject.panTo(position);
        vm.$refs.tripHistoryMap.$mapObject.setZoom(13);
      }, 300);
    },
    getGSMSignalPercent(g, type) {
      if (new RegExp(/^TELTONIKA/g).test(type)) {
        return g * 20 + "%";
      }
      {
        // conversion as per SRT documentation
        if (g <= 31) return Math.ceil(3.225 * g) + " %";
        else return "N/A";
      }
    },

    setupModal(alram_data) {
      this.initDate();
      this.isPlaying = false;
      this.infoWinOpen = false;
      this.infoOptions.content = null;
      this.reportFor = new Date(this.moment().startOf("day"));
      this.zoom = this.$store.state.mapZoom;
      this.center = this.$store.state.mapCenter;
      this.destinations = [{}];
      this.alarm.unit_name = alram_data.unit_name;
      this.imei_number = alram_data.imei_number;

      this.routeResults = [];
      this.routePlayedItems = [];
      this.trackedPoints = [];
      this.trackedPaths = [];
      this.trackedHistoryPoints = [];
      this.historyTripsPoints = [];
      this.trackedHistoryPaths = [];
      this.waypoints = [];
      this.startPosition = null;
      this.endPosition = null;

      this.getRouteResult();
    },
    hide() {
      this.$modal.hide("modal-trip-history");
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    // this.debounceDrivingOptions = _.debounce(this.setDrivingOptions, 2000);
  },

  mounted() {},
};
</script>
<style scoped>
.max-height-80vh {
  max-height: 80vh;
  overflow-y: auto; /* Enable scrolling */
}

.close-icon {
  display: none;
}
.destination:hover .close-icon {
  display: block;
}

.stop-icon {
  display: block;
}
.drag-icon {
  display: none;
}

.destination:hover .stop-icon {
  display: none;
}
.destination:hover .drag-icon {
  display: block;
  cursor: pointer;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.route-tab {
  max-height: 640px;
}
.route-summary {
  max-height: 400px;
}
.optimized-path {
  position: absolute;
  width: 25rem;
  left: 0px;
  z-index: 9999;
  margin-top: 60px;
  border: 2px solid #ddd;
  min-height: 83vh;
  max-height: 83vh;
  background: #fff;
  padding: 10px;
  overflow-y: scroll;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.sticky-info-panel {
  width: 360px;
  position: fixed;
  top: 15%;
  left: 60%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}
</style>

<template id="my-template">
  <div class="timeline-container">
    <div class="text-center">
      <h2 class="font-bold" v-if="showTitle">Optimized Route</h2>
      <div>
        <h1>Total {{ stats.totalDistance }}km</h1>
        <h1>About {{ stats.totalDuration }} hours</h1>
        <h1>Total waiting time {{ stats.totalDuration }} hours</h1>
      </div>
    </div>
    <hr />
    <div class="timeline">
      <div v-for="(path, i) in pathData" :key="i">
        <div class="date">
          <span class="fold text-xs">{{ path.start_address }}</span>
        </div>
        <div class="article">
          <span class="dot"></span>
          <div class="text-sm font-bold">
            <span class="font-bold">Stop {{ i + 1 }} </span>: {{ path.distance.text }}, About {{ path.duration.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showTitle"],
  data() {
    return {
      //pathData: this.routes,
    };
  },
  computed: {
    pathData() {
      return this.$store.getters.getOptimizedPath;
    },
    stats() {
      let fullData = this.$store.getters.getOptimizedPath;
      let totalDistance = 0;
      let totalDuration = 0;
      fullData.forEach((item) => {
        totalDistance = +item.distance.value;
        totalDuration = +item.duration.value;
      });

      totalDistance = (totalDistance / 100).toFixed(1);
      totalDuration = (totalDuration / 60).toFixed(1); //convert to hour
      if (totalDuration > 60) {
        totalDuration = (totalDuration / 60).toFixed(1);
      }
      return { totalDistance: totalDistance, totalDuration: totalDuration };
    },
    totalDuration() {
      let fullData = this.$store.getters.getOptimizedPath;
      return fullData;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
* {
  outline: none;
  box-sizing: border-box;
}

.timeline-container {
  margin: 0 auto;
  width: 84%;
}

.timeline-container .timeline {
  position: relative;
  border-left: 1px solid black;
}

.search-box {
  padding: 10px;
  margin: 20px 0;
  border: 1px solid black;
  border-radius: 5px;
}

.timeline {
  position: relative;
  border-left: 1px solid black;
}

.date {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  left: 15px;
  margin: 15px 0;
}

.date:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -16px;
  border: 1px solid black;
  width: 14px;
}

.article {
  position: relative;
  left: 20px;
  // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.article:hover {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
}

.article a {
  text-decoration: none;
}

.article .article-date {
  color: green;
  font-weight: 300;
  font-size: 14px;
}

.article .dot {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: green;
  left: -25.5px;
  top: calc(50% - 5px);
}
</style>

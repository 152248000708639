<template>
  <div>
    <div class="w-full flex justify-between py-2 bg-action-bar">
      <div class="flex flex-wrap text-left">
        <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="currentPageTab == 1 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeCurrentPage(1)">
          History
        </div>
        <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="currentPageTab == 2 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeCurrentPage(2)">
          Scheduled
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="datepicker-wrapper mt-2 lg:mt-0">
          <date-picker v-model="date_range" type="date" :lang="date_picker_lang" :key="date_picker_langKey" :editable="false" :clearable="false" @change="getAlarmTrips()" :disabled-date="disableDate" :disabled="true"> </date-picker>
        </div>
        <div class="select-wrapper mx-2 mt-2 lg:mt-0" v-if="type == 'customer' && units && currentPageTab === 2">
          <select class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-1 py-1" v-model="selected_unit" @change="selectChangeFilter()">
            <option value="">{{ $t("secure_tool.all_activities") }}</option>
            <option v-for="u in units" :key="u._id" :value="u.unit_id">{{ u.name }}</option>
          </select>
        </div>
        <div class="select-wrapper mx-2 mt-2 lg:mt-0" v-if="alarms.length > 0">
          <!-- <select class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 disabled:opacity-50 px-1 py-1" v-model="selected_alarm" @change="selectChangeFilterAlarm()" :disabled="selected_unit == '' && type == 'customer'" :readonly="selected_unit == '' && type == 'customer'">
            <option value="">{{ $t("secure_tool.all_alarms") }}</option>
            <option v-for="a in dropdown_alarms" :key="a._id" :value="a.imei_number">{{ a.unit_name }}</option>
          </select> -->
        </div>
      </div>
    </div>
    <div class="w-full mt-6">
      <vue-good-table
        v-if="currentPageTab === 1"
        styleClass="vgt-table"
        row-style-class="text-sm"
        :columns="historyTablecolumns"
        :rows="deviceList"
        :search-options="{
          enabled: true,
        }"
        :isLoading.sync="isLoading"
        mode="remote"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'department_name'">
            {{ props.row.department_name }}
          </span>
          <span v-if="props.column.field === 'unit_name'">
            <span v-if="props.row.hasOwnProperty('isActive')">
              <span class="last-seen" v-bind:class="{ inactive: props.row.isActive, orange: !props.row.isActive }">⬤</span>
            </span>
            <span v-else>
              <span class="last-seen grey">⬤</span>
            </span>
            <span class="pl-1">{{ props.row.unit_name }}</span>
          </span>
          <span v-if="props.column.field == 'view'">
            <button class="btn-blue-outline" @click.prevent="showTrip(props.row)">
              view
            </button>
          </span>
        </template>
      </vue-good-table>
      <vue-good-table
        v-if="currentPageTab === 2"
        styleClass="vgt-table"
        row-style-class="text-sm"
        :columns="columns"
        :rows="trips"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="paginagion_params"
        :isLoading.sync="isLoading"
        @on-column-filter="onColumnFilter"
        mode="remote"
      >
        <template slot="column-filter" slot-scope="{ column, updateFilters }">
          <div class="inline-block w-80 border-separate whitespace-pre-line" v-if="column.filterOptions && column.filterOptions.customFilter">
            <div class="text-xs font-normal font-sans" v-if="column.field == 'customer_name'">
              <treeselect v-model="selectedUnits" :options="customerOptions" @close="updateFilters(column, selectedUnits)" :beforeClearAll="clearAllUnits" :multiple="true" value-consists-of="ALL" noChildrenText="Ingen verksamhet" />
            </div>
            <div class="text-xs font-normal font-sans" v-if="column.field == 'product_name'">
              <treeselect v-if="selectedUnits.length > 0 && $store.getters.getDeviceOptions.length > 0" v-model="selectedAlarm" :options="$store.getters.getDeviceOptions" @close="updateFilters(column, selectedAlarm)" :multiple="true" />
            </div>
          </div>
        </template>
        <div slot="table-actions">
          <div class="flex flex-wrap">
            <button class="btn-secondary-outline mx-1 mt-2 lg:mt-0" @click="resetFilters()">
              {{ $t("secure_tool.reset") }}
              <BaseIcon icon="undo" class="ml-1" />
            </button>
            <button class="btn-blue-outline mx-1 mt-2 lg:mt-0" @click="refreshData()">
              {{ $t("asset_tracking_page.refresh") }}
              <BaseIcon icon="sync" class="ml-1" />
            </button>
            <tooltip message="wkrótce">
              <button class="btn-blue mx-1 mt-2 lg:mt-0" @click="createNewTrip()">
                {{ $t("asset_tracking_page.new_tour") }}
                <BaseIcon icon="plus" class="ml-1" />
              </button>
            </tooltip>
          </div>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'product_name'">
            <!-- <span v-if="props.row.gps_last_seen" class="text-xxs last-seen mr-1" v-bind:class="{ inactive: new Date().getTime() - new Date(props.row.gps_last_seen).getTime() > 1000 * 60 * 60 }">⬤</span> -->
          </span>
          <span v-if="props.column.field == 'edit'">
            <button class="btn-blue-outline" @click.prevent="planTrip(props.row)">
              <BaseIcon v-if="props.row.trip_state.tripStarted == false" icon="pencil-alt" />
              <BaseIcon v-else icon="info" />
            </button>
          </span>
          <span v-else-if="props.column.field == 'delete'">
            <button class="btn-red-outline" @click.prevent="deleteTripPrompt(props.row)">
              <BaseIcon icon="trash" class="" />
            </button>
          </span>
          <span v-else-if="props.column.field == 'status'">
            <span v-if="props.row.trip_state.tripStarted == false && props.row.trip_state.tripEnded == false" class="text-secondary-600">Inte startat</span>
            <span v-else-if="props.row.trip_state.tripStarted == true && props.row.trip_state.tripEnded == false" class="text-yellow-500">Pågår</span>
            <span v-else-if="props.row.trip_state.tripStarted == true && props.row.trip_state.tripEnded == true" class="text-accent-500">Avslutad</span>
          </span>
          <span v-else-if="props.column.field == 'route'">
            <div class="text-left">
              <div class="icon-with-text">
                <BaseIcon icon="map-marker" class="" />
              </div>
              {{ props.row.start_address }}
            </div>
            <div class="text-center"><BaseIcon icon="arrow-down" /></div>
            <div class="text-left">
              <div class="icon-with-text"><BaseIcon icon="map-marker" class="" /></div>
              {{ props.row.end_address }}
            </div>
          </span>
          <span v-else-if="props.column.field == 'distance'"> {{ (props.row.total_distance / 1000).toFixed(2) }} km</span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <TripReport ref="tripReportModal" :gpss="gpss_data" :updateInfo="updateInfo" @on-unit-select="filterUnit" @routeSaved="getAlarmTrips" />
    <TripHistory ref="tripHistoryModal" />
  </div>
</template>

<script>
import TripReport from "@/components/map/TripReport";
import TripHistory from "@/components/map/TripHistory";
import tooltip from "@/components/base/tooltip";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  title() {
    return this.$t("asset_tracking_page.title");
  },

  components: {
    TripReport,
    TripHistory,
    DatePicker,
    Treeselect,
    tooltip,
  },

  data() {
    return {
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      imei: this.$route.params.imei,
      user: this.$store.state.user,
      type: this.$route.params.type,
      isLoading: false,
      currentPageTab: 1,
      selected_unit: "",
      selected_alarm: "",
      date_range: new Date(this.moment().startOf("day")),
      date_picker_lang: "en",
      date_picker_langKey: 0,
      trips: [],
      deviceList: [],
      units: [],
      gpss_data: [],
      alarms: [],
      alarmList: [],
      customerOptions: [],
      selectedUnits: [],
      selectedAlarm: [],
      deviceFilter: [],
      deviceOption: [],
      customers: [],
      updateInfo: [],
      server_params: {
        filters: {
          unit_id: [], //"20210809-7381-4", "20210205-7381-3", "20230315-7381-7", "651ac0808715da7f54c19f8e", "651ac0988715da7f54c19fb8", "65813527ee69ce996d8cd918"
          imei_number: [], //"866907059025162"
        },
        startDate: "2023-01-20T00:30:00.000Z", //2023-01-20T00:30:00.000Z
        endDate: "2023-12-30T23:59:59.999Z", //2023-12-30T23:59:59.999Z
        sort: [{ field: "createdAt", type: "desc" }],
        page: 1,
        perPage: 100,
      },
      paginagion_params: {
        enabled: true,
        position: "bottom",
        mode: "pages",
        jumpFirstOrLast: true,
        perPage: 100,
        perPageDropdown: [500, 1000, 1500],
        dropdownAllowAll: false,
        pageLabel: "page",
        rowsPerPageLabel: "per page",
      },
    };
  },
  computed: {
    columns() {
      return [
        { label: this.$t("asset_tracking_table.customer_name"), field: "customer_name", sortable: false, filterOptions: { enabled: true, customFilter: true } },
        { label: this.$t("asset_tracking_table.product_name"), field: "product_name", filterOptions: { enabled: true, customFilter: true } },
        { label: this.$t("asset_tracking_table.route"), field: "route", sortable: false },
        { label: this.$t("asset_tracking_table.distance"), field: "distance", sortable: false },
        { label: this.$t("asset_tracking_table.trip_duration"), field: "total_duration", sortable: false, formatFn: this.formatDuration },
        { label: this.$t("asset_tracking_table.departure_time"), field: "departure_time", formatFn: this.formatDate },
        { label: this.$t("asset_tracking_table.date"), field: "createdAt", formatFn: this.formatDate },
        { label: this.$t("asset_tracking_table.status"), field: "status" },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "delete", sortable: false },
      ];
    },
    historyTablecolumns() {
      return [
        { label: this.$t("asset_tracking_table.customer_name"), field: "department_name", sortable: true },
        { label: this.$t("asset_tracking_table.product_name"), field: "unit_name", sortable: true },
        { label: "", field: "view", sortable: false },
      ];
    },
  },
  methods: {
    getAlarmTrips() {
      const URL = `${process.env.VUE_APP_SERVER_URL}/asset-tracker/trips/${this.$route.params.id}`;
      //const URL = `${process.env.VUE_APP_SERVER_URL}/asset-tracker/trips`;

      this.isLoading = true;
      this.axios
        .post(URL, this.server_params)
        .then((response) => {
          //console.log("🚀 ~ file: trip_report.vue:176 ~ .then ~ response:", response);

          this.isLoading = false;
          this.getCustomerAndUnits();

          this.trips = response.data.trips;
          let customers = response.data.customers;
          this.alarms = response.data.alarms;
          this.gpss_data = response.data.gpss;
          this.$store.commit("SET_GPS_DATA", this.gpss_data);

          this.trips.forEach((trip) => {
            const customer = customers.find((c) => c.customer_id === trip.customer_id);
            const device = this.alarms.find((c) => c.imei_number === trip.imei_number);

            if (customer) {
              trip.customer_name = customer.name;
            }

            if (device) {
              trip.product_name = device.unit_name;
            }
          });

          if (this.selectedUnits.length > 0) {
            let deviceList = this.alarms.filter((alarm) => this.selectedUnits.includes(alarm.parent_id));
            this.alarmList = deviceList.map((alarm) => ({
              label: alarm.unit_name,
              id: alarm.imei_number,
            }));

            this.$store.commit("SET_DEVICE_OPTIONS", this.alarmList);
            this.$store.commit("SET_ALARMS", this.alarms);
          }

          //this.setPageTitle(`Tillgångsspårning (α) - ${response.data.alarm.unit_name}`, "asset_tracker_alarm");
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
        });
    },
    getCustomerAndUnits() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/customer`)
        .then((response) => {
          this.customers = response.data.customers;
          this.units = response.data.units;
          this.customerOptions = response.data.unitOptions;
          let unitOptionsForNewTrip = response.data.unitOptions;

          if (this.alarms.length > 0 && unitOptionsForNewTrip.length > 0) {
            this.alarms.forEach((alarm) => {
              unitOptionsForNewTrip.forEach((unit) => {
                unit.children.forEach((child) => {
                  // Ensure child.children is an array
                  if (!Array.isArray(child.children)) {
                    child.children = [];
                  }

                  if (child.id === alarm.parent_id) {
                    let newUnit = {
                      id: alarm.imei_number,
                      label: alarm.unit_name,
                    };
                    // Add the newUnit to the child's children array
                    child.children.push(newUnit);
                  }
                });
              });

              //prepare trip history data
              this.units.forEach((unit) => {
                if (unit.unit_id === alarm.parent_id) {
                  this.deviceList.push({ department_name: unit.name, unit_name: alarm.unit_name, imei_number: alarm.imei_number });
                }
              });
              this.setDeviceStatus();
            });

            // this.alarms.forEach((alarm) => {
            //   unitOptionsForNewTrip.forEach((unit) => {
            //     unit.children.forEach((child) => {
            //       if (child.id === alarm.parent_id) {
            //         let newUnit = {
            //           id: alarm.imei_number,
            //           label: alarm.unit_name,
            //         };

            //         // Ensure child.children is an array
            //         if (!Array.isArray(child.children)) {
            //           child.children = [];
            //           // Add the newUnit to the child's children array
            //           child.children.push(newUnit);
            //         }
            //       }
            //     });
            //   });
            // });

            //console.log("🚀  ~ customerOptions:", JSON.stringify(this.customerOptions));
            //console.log("🚀  ~ unitOptionsForNewTrip:", unitOptionsForNewTrip);
            this.$store.commit("SET_UNIT_OPTIONS", unitOptionsForNewTrip);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    deleteTripPrompt(trip) {
      this.$modal.show("dialog", {
        title: `<span class="font-normal text-sm">Ta bort resa från <b>${trip.start_address}</b> till <b>${trip.end_address}</b> ?</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
            handler: () => {
              this.deleteTrip(trip._id);
            },
          },
        ],
      });
    },
    updateFilters() {
      //console.log("🚀 ~ file: trip_report.vue:378 ~ updateFilters ~ value:", value);
      //console.log("🚀 ~ file: trip_report.vue:378 ~ updateFilters ~ params:", params);
    },
    beforeClearAll() {
      //console.log("🚀 ~ beforeClearAll ");
    },
    onColumnFilter() {
      if (this.selectedUnits) {
        this.alarmList = [];
        this.server_params.filters.unit_id = this.selectedUnits;
        this.server_params.page = 1;
      }

      if (this.selectedAlarm) {
        this.server_params.filters.imei_number = this.selectedAlarm;
        this.server_params.page = 1;
      }
      this.getAlarmTrips();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    clearAllUnits() {
      this.server_params.filters.unit_id = this.selectedUnits;
      this.server_params.page = 1;

      //if (this.serverParams.filters && this.serverParams.filters.parent_id) delete this.serverParams.filters.parent_id;
      this.selectedUnits = [];
      this.getAlarmTrips();
    },
    refreshData() {
      this.getAlarmTrips();
    },
    filterUnit(data) {
      if (data.length > 0) {
        this.server_params.filters.unit_id = data;
        this.server_params.page = 1;
        this.selectedUnits = data;
        this.getAlarmTrips();
      }
    },
    async deleteTrip(_id) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/asset-tracker/trip/${_id}/${this.imei}`);

        this.getAlarmTrips();
        this.$modal.hide("dialog");
        this.handleSuccess("Larm har raderats");
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },
    createNewTrip() {
      this.updateInfo = null;
      this.$refs.tripReportModal.initDate();
      this.$refs.tripReportModal.isAlarmSelectDisabled = false;

      this.$modal.show("modal-trip-tracker");
    },
    showTrip(trip) {
      //console.log("🚀 ~ showTrip ~ trip:", trip);
      this.$refs.tripHistoryModal.setupModal(trip);
      this.$modal.show("modal-trip-history");
    },
    planTrip(trip) {
      var vm = this;
      this.updateInfo = trip;
      this.$nextTick().then(() => {
        vm.$refs.tripReportModal.setupTripInfo();
      });
      this.$modal.show("modal-trip-tracker");
    },
    formatDuration(seconds) {
      return this.moment.duration(seconds, "seconds").humanize();
    },
    formatDate(date) {
      return this.moment(date)
        .tz("Europe/Stockholm")
        .format("YYYY-MM-DD, HH:mm");
    },
    formatTime(time) {
      const m = Math.floor(time / 60);
      const s = Math.floor((time % 3600) % 60);
      const minutes = m < 9 ? "0" + m : m;
      return minutes + ":" + s;
    },
    formatDistance(meter) {
      if (meter) {
        return (meter / 1000).toFixed(2) + " Km";
      } else {
        return "0Km";
      }
    },
    selectCurrentWeek(emit) {
      const start = new Date(this.moment().startOf("isoWeek"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },
    selectLastWeek(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
      );
      const end = new Date(
        this.moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      );
      const date = [start, end];
      emit(date);
    },
    selectCurrentMonth(emit) {
      const start = new Date(this.moment().startOf("month"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    initDateRange() {
      const start = new Date(this.moment().startOf("day"));
      // const end = new Date(this.moment().endOf("day"));
      this.date_range = start;
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },

    selectToday(emit) {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },
    resetFilters() {
      this.server_params = {
        filters: {
          unit_id: [],
          imei_number: [],
        },
        startDate: this.moment()
          .startOf("day")
          .toISOString(),
        endDate: this.moment()
          .endOf("day")
          .toISOString(),
        sort: [{ field: "createdAt", type: "desc" }],
        page: 1,
        perPage: 100,
      };
      this.alarmList = [];
      this.customerOptions = [];
      this.customerOptions = [];
      this.selectedAlarm = [];
      this.selectedUnits = [];

      this.getAlarmTrips();
    },
    setDeviceStatus() {
      for (let i = 0; i < this.deviceList.length; i++) {
        this.gpss_data.forEach((item) => {
          if (this.deviceList[i].imei_number === item.imei_number) {
            let thisMoment = this.moment(item.updatedAt);
            let now = this.moment();
            // Calculate the difference in minutes
            let differenceInMinutes = now.diff(thisMoment, "minutes");
            // Check if the difference is within 60 minutes (1 hour)
            if (differenceInMinutes >= 0 && differenceInMinutes <= 60) {
              // Device was updated within the last hour
              this.deviceList[i].isActive = true;
            } else {
              this.deviceList[i].isActive = false;
            }
          }
        });
      }
    },

    changeCurrentPage(value) {
      this.currentPageTab = value;

      // if (this.currentPageTab == 1) {
      //   this.generateBounds();
      //   this.showTripMap = false;
      // }
    },
  },
  created() {
    //console.log("moment=> ", this.moment().toDate());
  },
  mounted() {
    this.initDateRange();
    this.getAlarmTrips();
    this.$store.commit("SET_PAGE_TITLE", this.$t("asset_tracking_page.title"));
  },
  watch: {
    "$i18n.locale"(newLocale) {
      document.title = this.$t("asset_tracking_page.title");
      this.$store.commit("SET_PAGE_TITLE", this.$t("asset_tracking_page.title"));
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },
};
</script>
<style scoped>
.icon-with-text {
  position: relative;
  display: inline-block;
  color: red;
}
.bg-action-bar {
  background: #f6faff;
}
</style>

<template>
  <modal name="trip-share" class="modal-inner modal-update-addon" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide('trip-share')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span>Share trip</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateAddon">
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseInput type="text" field_name="Email" rules="required" />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseTextarea rows="5" field_name="Description" rules="required" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('trip-share')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" :disabled="true">Spara<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
  props: {},
  data() {
    return {
      updateInfo: null,
      updateImage: null,
      previewImage: null,
    };
  },

  computed: {},

  methods: {
    hide(id) {
      this.$refs.formUpdateAddon.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
